import React, {useEffect} from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	Text,
	VStack,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import parseISO from 'date-fns/parseISO';
import {useNavigate} from 'react-router-dom';
import {AppointmentDate} from '../../../utils/format';
import BookingRequestFollowUpController from 'puddleglum/api/employee/BookingRequestFollowUpController';

const BookingRequestFollowUpModal = () => {
	const {isOpen, onOpen, onClose} = useDisclosure();

	const navigate = useNavigate();

	const {data: bookingRequest} = useQuery(['booking-request-follow-up'], async () => {
		const reply = await BookingRequestFollowUpController.index();
		return reply.data;
	});

	const handleUpdate = async (followUpAnswer: string) => {
		if (!bookingRequest) return;

		await BookingRequestFollowUpController.update(bookingRequest.id, {
			follow_up_answer: followUpAnswer,
		});

		onClose();

		if (followUpAnswer === 'needed') {
			const treatmentGroupIds = bookingRequest.orders
				?.map((o) => o.treatment_group_id)
				.filter((t) => t);
			const treatmentIds = bookingRequest.orders?.map((o) => o.treatment_id).filter((t) => t);

			navigate('/booking-request', {
				state: {
					organization_id: bookingRequest.organization_id,
					location_id: bookingRequest.location_id,
					provider_id: bookingRequest.provider_id,
					treatment_group_ids:
						treatmentGroupIds && treatmentGroupIds.length > 0
							? treatmentGroupIds
							: undefined,
					treatment_ids:
						treatmentIds && treatmentIds?.length > 0 ? treatmentIds : undefined,
				},
			});
		}
	};

	useEffect(() => {
		if (bookingRequest) {
			onOpen();
		}
	}, [bookingRequest, onOpen]);

	if (!bookingRequest || !bookingRequest.id) return null;

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={{base: 'full', sm: 'lg'}}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Follow Up Information Needed</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>
						Thank you for your appointment with{' '}
						<strong>{bookingRequest.provider?.name}</strong> on{' '}
						<strong>{AppointmentDate(parseISO(bookingRequest.starts_at!))}</strong>.
					</Text>

					<Text mt={4}>Please let us know what you’d like to do next:</Text>

					<VStack w='full' alignItems='flex-start' spacing={4} my={8}>
						<Button
							w='full'
							variant='outline'
							onClick={() => handleUpdate('not-needed')}
						>
							I don&apos;t need a follow-up visit.
						</Button>
						<Button
							w='full'
							variant='outline'
							onClick={() => handleUpdate('already-booked')}
						>
							I have already booked a follow-up visit.
						</Button>
						<Button w='full' variant='outline' onClick={() => handleUpdate('needed')}>
							I need to book a follow-up visit.
						</Button>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default BookingRequestFollowUpModal;
