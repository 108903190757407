/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, {AxiosRequestConfig} from 'axios';
import {Glum} from 'puddleglum';
import {transformToQueryString, PaginatedResponse} from 'puddleglum/utils';

export default class PaymentController {
	static async getBillingStatements(
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<Glum.Models.BillingStatement[]>(`/api/employee/billing-statement`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async getBillingStatement(
		billingStatement: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<{
			billing_statement: Glum.Models.BillingStatement;
			stripe_client_secret: string;
		}>(`/api/employee/billing-statement/${billingStatement}`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async getBillingStatementByBookingRequest(
		bookingRequest: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<{
			billing_statement: Glum.Models.BillingStatement;
			stripe_client_secret: string;
		}>(`/api/employee/booking-request/${bookingRequest}/billing-statement`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async getRecentPayments(
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<Glum.Models.BillingStatement[]>(`/api/employee/recent-payments`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}
}
