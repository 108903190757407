import React, {FC, useEffect, useRef, useState} from 'react';
import {
	Box,
	BoxProps,
	Center,
	Heading,
	HStack,
	Icon,
	IconButton,
	SkeletonCircle,
	SkeletonText,
	Text,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {IconType} from 'react-icons';
import {
	GiHeartOrgan,
	GiBrain,
	GiTooth,
	GiEyeball,
	GiDogHouse,
	GiPill,
	GiFamilyTree,
	GiSoccerBall,
	GiMuscleUp,
	GiLungs,
	GiWeightLiftingUp,
	GiMedicines,
	GiStethoscope,
	GiMedicalDrip,
	GiSyringe,
	GiNurseMale,
	GiTestTubes,
	GiMedicalPackAlt,
	GiBabyFace,
	GiFemale,
	GiSkeleton,
	GiHumanEar,
} from 'react-icons/gi';
import {MdArrowBack, MdArrowForward} from 'react-icons/md';
import SpecialtySearchController from 'puddleglum/api/employee/SpecialtySearchController';

interface CommonSearchesProps extends BoxProps {
	onSelectSearch: (selection: string) => void;
}

const CommonSpecialtySearches: FC<CommonSearchesProps> = ({onSelectSearch, ...boxProps}) => {
	const specialtyContainer = useRef<HTMLDivElement>(null);
	const [canMoveFwd, setCanMoveFwd] = useState(false);
	const [canMoveBwd, setCanMoveBwd] = useState(false);

	const {data: specialties, isLoading} = useQuery(['specialties'], async () => {
		const reply = await SpecialtySearchController.index();
		return reply.data;
	});

	const guessForBestIcon = (search: string): IconType => {
		if (search.toLowerCase().includes('cardio')) {
			return GiHeartOrgan; // Heart organ icon for cardiology
		}
		if (search.toLowerCase().includes('derm')) {
			return GiMedicalPackAlt; // Medical pack icon for dermatology
		}
		if (search.toLowerCase().includes('dent')) {
			return GiTooth; // Tooth icon for dentistry
		}
		if (search.toLowerCase().includes('psych')) {
			return GiBrain; // Brain icon for psychology/psychiatry
		}
		if (search.toLowerCase().includes('obgyn')) {
			return GiFemale; // Pregnant woman icon for obstetrics/gynecology
		}
		if (search.toLowerCase().includes('pedia')) {
			return GiBabyFace; // Child icon for pediatrics
		}
		if (search.toLowerCase().includes('ophthalm')) {
			return GiEyeball; // Eyeball icon for ophthalmology
		}
		if (search.toLowerCase().includes('otolaryng')) {
			return GiHumanEar; // Ear icon for otolaryngology (ENT)
		}
		if (search.toLowerCase().includes('vet')) {
			return GiDogHouse; // Dog house icon for veterinary
		}
		if (search.toLowerCase().includes('ortho')) {
			return GiSkeleton; // Bone icon for orthopedics
		}
		if (search.toLowerCase().includes('pharm')) {
			return GiPill; // Pill icon for pharmacology
		}
		if (search.toLowerCase().includes('primary') || search.toLowerCase().includes('fam')) {
			return GiFamilyTree; // Family tree icon for primary care/family medicine
		}
		if (search.toLowerCase().includes('sports')) {
			return GiSoccerBall; // Soccer ball icon for sports medicine
		}
		if (search.toLowerCase().includes('neuro')) {
			return GiBrain; // Nervous system icon for neurology
		}
		if (search.toLowerCase().includes('geri')) {
			return GiNurseMale; // Male nurse icon for geriatrics
		}
		if (search.toLowerCase().includes('rheum')) {
			return GiMuscleUp; // Muscle icon for rheumatology
		}
		if (search.toLowerCase().includes('pulm')) {
			return GiLungs; // Lungs icon for pulmonology (lungs)
		}
		if (search.toLowerCase().includes('rehab') || search.toLowerCase().includes('physiatry')) {
			return GiWeightLiftingUp; // Weight lifting icon for physical medicine and rehabilitation
		}
		if (search.toLowerCase().includes('allerg')) {
			return GiMedicines; // Medicines icon for allergy/immunology
		}
		if (search.toLowerCase().includes('endocrin')) {
			return GiSyringe; // Syringe icon for endocrinology (metabolism)
		}
		if (search.toLowerCase().includes('gastro')) {
			return GiStethoscope; // Stethoscope icon for gastroenterology
		}
		if (search.toLowerCase().includes('immun')) {
			return GiMedicines; // Medicines icon for immunology
		}
		if (search.toLowerCase().includes('infect')) {
			return GiTestTubes; // Test tubes icon for infectious disease
		}
		if (search.toLowerCase().includes('onc')) {
			return GiMedicalDrip; // Medical drip icon for oncology
		}
		if (search.toLowerCase().includes('surge')) {
			return GiMedicalPackAlt; // Medical pack icon for surgery
		}

		return GiMedicalPackAlt; // Default medical pack icon
	};

	const moveForward = () => {
		if (specialtyContainer.current) {
			specialtyContainer.current.scrollTo({
				left: specialtyContainer.current.scrollLeft + 200,
				behavior: 'smooth',
			});
		}
	};

	const moveBackward = () => {
		if (specialtyContainer.current) {
			specialtyContainer.current.scrollTo({
				left: specialtyContainer.current.scrollLeft - 200,
				behavior: 'smooth',
			});
		}
	};

	const updateScrollButtons = () => {
		if (specialtyContainer.current) {
			setCanMoveFwd(
				specialtyContainer.current.scrollLeft <
					specialtyContainer.current.scrollWidth - specialtyContainer.current.clientWidth,
			);
			setCanMoveBwd(specialtyContainer.current.scrollLeft > 0);
		}
	};

	useEffect(() => {
		updateScrollButtons();
		if (specialtyContainer.current) {
			specialtyContainer.current.addEventListener('scroll', updateScrollButtons);
		}
		return () => {
			if (specialtyContainer.current) {
				specialtyContainer.current.removeEventListener('scroll', updateScrollButtons);
			}
		};
	}, [specialties]);

	if (isLoading)
		return (
			<HStack w='full' overflowX='hidden' {...boxProps}>
				{[...Array(5)].map((_, idx) => (
					// eslint-disable-next-line react/no-array-index-key
					<Box key={idx} w='150px' padding='8' boxShadow='lg' bg='white'>
						<SkeletonCircle size='10' />
						<SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='2' />
					</Box>
				))}
			</HStack>
		);

	if (!specialties) return null;

	return (
		<Box {...boxProps} pos='relative'>
			<Heading mt={4} mb={4} size='md' color='gray.500'>
				Common Searches
			</Heading>
			<IconButton
				onClick={moveBackward}
				display={canMoveBwd ? 'block' : 'none'}
				pos='absolute'
				left={4}
				top={16}
				boxShadow='md'
				borderRadius='full'
				variant='outline'
				colorScheme='blue'
				bgColor='white'
				aria-label='Previous'
				icon={<Icon as={MdArrowBack} />}
			/>
			<IconButton
				onClick={moveForward}
				display={canMoveFwd ? 'block' : 'none'}
				pos='absolute'
				right={4}
				top={16}
				boxShadow='md'
				borderRadius='full'
				variant='outline'
				colorScheme='blue'
				bgColor='white'
				aria-label='Next'
				icon={<Icon as={MdArrowForward} />}
			/>
			<HStack ref={specialtyContainer} w='full' overflowX='hidden'>
				{specialties.map((specialty) => (
					<Box
						key={specialty.id}
						role='button'
						onClick={() => onSelectSearch(specialty.name)}
						bgColor='white'
						minW='200px'
						border='solid 1px'
						borderRadius='md'
						borderColor='gray.200'
						color='gray.400'
						_hover={{
							color: 'blue.500',
							bgColor: 'blue.50',
							cursor: 'pointer',
						}}
					>
						<Center mt={4}>
							<Icon as={guessForBestIcon(specialty.name)} boxSize={8} />
						</Center>
						<Center mb={4}>
							<Text noOfLines={1}>{specialty.name}</Text>
						</Center>
					</Box>
				))}
			</HStack>
		</Box>
	);
};

export default CommonSpecialtySearches;
