import React, {FC} from 'react';
import {
	Box,
	Button,
	Heading,
	HStack,
	Link,
	Tag,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import parseISO from 'date-fns/parseISO';
import {useNavigate} from 'react-router-dom';
import {Glum} from '../../../puddleglum';
import {AppointmentDate} from '../../../utils/format';
import PendingBillingStatementController from 'puddleglum/api/employee/PendingBillingStatementController';

const PendingBillingStatements: FC = () => {
	const navigate = useNavigate();

	const {data: billingStatements} = useQuery(['pending-billing-statements'], async () => {
		const {data} = await PendingBillingStatementController.index();
		return data;
	});

	const navigateToBillPayScreen = (billingStatement: Glum.Models.BillingStatement) => {
		navigate('/pay-bill', {
			state: {
				billingStatement,
			},
		});
	};

	if (!billingStatements || billingStatements.length === 0) return null;

	return (
		<Box w='full'>
			<Heading mb={2} size='md' color='gray.500'>
				Pending Billing Statements
			</Heading>
			<VStack spacing={2} w='full'>
				{billingStatements?.map((billingStatement) => (
					<Box
						key={billingStatement.id}
						w='full'
						p={4}
						border='solid 1px'
						borderColor='yellow.500'
						borderRadius='md'
						bgColor='yellow.50'
					>
						<HStack w='full' justifyContent='space-between'>
							<Text fontSize='sm' color='cyan.500' fontWeight='semibold'>
								{AppointmentDate(parseISO(billingStatement.created_at!))}
							</Text>
						</HStack>

						<HStack w='full' justifyContent='space-between'>
							<Heading mt='2' size='md' fontWeight='medium'>
								{billingStatement.organization?.name}
								{billingStatement.pharmacy?.name}
							</Heading>
						</HStack>

						<Wrap>
							{(billingStatement.orders ?? [])
								.filter((order) => order.status !== 'canceled')
								.map((order) => (
									<WrapItem key={order.id}>
										<Tag
											overflow='hidden'
											whiteSpace='nowrap'
											maxW='200px'
											colorScheme={
												Number(
													order.billing_statement?.employee_balance,
												) === 0
													? 'green'
													: 'gray'
											}
										>
											{order.treatment?.treatment}
											{order.treatment_group?.name}
											{order.drug?.brand_name}
										</Tag>
									</WrapItem>
								))}
						</Wrap>

						<HStack mt='2' w='full' justifyContent='space-evenly'>
							<Button
								colorScheme='cyan'
								color='white'
								w='full'
								onClick={() => navigateToBillPayScreen(billingStatement)}
							>
								Pay Bill
							</Button>
							<Button
								variant='outline'
								w='full'
								as={Link}
								href={`tel:${billingStatement.organization?.phone ?? billingStatement.pharmacy?.phone}`}
							>
								Call {billingStatement.pharmacy_id ? 'Pharmacy' : 'Office'}
							</Button>
						</HStack>
					</Box>
				))}
			</VStack>
		</Box>
	);
};

export default PendingBillingStatements;
