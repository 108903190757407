/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, {AxiosRequestConfig} from 'axios';
import {Glum} from 'puddleglum';
import {transformToQueryString, PaginatedResponse} from 'puddleglum/utils';

export default class ProcedureSearchAutocompleteController {
	static async index(
		request: {search: string},
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<Array<{id: string; name: string; type: string}>>(
			`/api/employee/autocomplete/procedures?${transformToQueryString(request)}`,
			{
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			},
		);
	}
}
