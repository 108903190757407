/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import {
	Center,
	Image,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Progress,
} from '@chakra-ui/react';
import assetUrl from '../utils/assetUrl';

interface LoadingModalProps {
	isOpen: boolean;
	steps: string[];
	estimatedTime: number;
	onClose: () => void;
}

const LoadingModal = ({isOpen, steps, estimatedTime, onClose}: LoadingModalProps) => {
	const [currentStep, setCurrentStep] = useState(0);

	const intervalRef = useRef<number | null>(null);

	useEffect(() => {
		if (isOpen) {
			setCurrentStep(0);

			intervalRef.current = setInterval(() => {
				setCurrentStep((c) => {
					if (c === steps.length - 1) {
						return c;
					}

					return c + 1;
				});
			}, estimatedTime / steps.length);
		}

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps -- We don't want to re-run this effect when currentStep changes
	}, [isOpen]);

	return (
		<Modal size='xs' isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<Center pt={8}>
					<Image
						src={assetUrl('/images/allora-logo.png')}
						alt='Allora Health'
						w='52px'
						m='auto'
					/>
				</Center>

				<ModalHeader textAlign='center' color='gray.900'>
					{steps[currentStep]}
				</ModalHeader>
				<ModalBody>
					<Progress mb={4} isIndeterminate />
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default LoadingModal;
